<template>
  <div>
    <div class="header text-center mt-5">
      <img src="@/assets/images/logo/flipmoney.png" class="logo" />
    </div>
    <vs-card class="container">
      <div class="mt-5">
          <h4 class="header">
          <strong>
           {{ legals.header }}
          </strong>
        </h4>
        <quill-editor
          class="mt-5"
          ref="myQuillEditor"
          v-model="legals.content"
          :options="options"
          disabled
        />
      </div>
    </vs-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: "PrivacyPolicy",
  components: {
    quillEditor
  },
  data() {
    return {
      legals: {
        header: "",
        content: "",
      },
       options :{
        debug: 'info',
        modules: {
          toolbar: false
        },
        readOnly: true,
      }
    };
  },
  methods: {
    fetchAllLegals() {
      this.$vs.loading();
      this.$store
        .dispatch("legals/fetchAllLegals")
        .then((result) => {
          this.$vs.loading.close();
          this.legals = this.getLegalByType("PRIVACY_POLICY_TC");

        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
  computed: {
    ...mapGetters("legals", ["getLegalByType"]),
  },
  mounted() {
    this.fetchAllLegals();
  },
};
</script>
